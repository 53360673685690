import React from 'react';
import { motion } from 'framer-motion';
import {
  BarChart2,
  Wallet,
  Calculator,
  PiggyBank,
  LineChart,
  TrendingUp,
  FileText,
  DollarSign,
} from 'lucide-react';
import { HQLogo } from './HQLogo';

const cards = [
  {
    title: 'Accounting Services',
    content: 'Manage your accounting Services needs effortlessly',
    href: '/accounting_services',
    icon: BarChart2,
  },
  {
    title: 'Accounting Services - QBO Integration',
    content: 'Extract, Transform and Load data into QBO',
    href: '/as_changelog',
    icon: FileText,
  },
  {
    title: 'Bundling Automation',
    content: 'Manage your bundling processes quickly and with ease',
    href: '/bundling_automation',
    icon: Wallet,
  },
  {
    title: 'NabiFour CMS',
    content: 'Manage your Credit Memo process with ease',
    href: '/nabifour_cms',
    icon: Calculator,
  },
  {
    title: 'Invoice Creation',
    content: 'Manage your Invoice Generation process with ease',
    href: '/as_invoice_creation',
    icon: LineChart,
  },
  {
    title: 'Cash Apps Dashboard',
    content: 'Secure your financial overview',
    href: '/cash_apps_dashboard',
    icon: DollarSign,
  },
  {
    title: 'Payment Status',
    content: 'Check your payment status easily',
    href: '/flip_pmt_status',
    icon: TrendingUp,
  },
  {
    title: 'Payment Processing',
    content: 'Manage your payment processing process with ease',
    href: '/gunnebo_processing',
    icon: PiggyBank,
  },
];

export default function FinanceApps() {
  return (
    <div className='min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white flex flex-col'>
      <main className='flex-grow container mx-auto px-4 py-8 flex flex-col'>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className='mb-12'
        >
          <h1 className='text-4xl md:text-5xl font-extrabold text-center bg-clip-text text-transparent bg-gradient-to-r from-[#4295A5] to-[#2A5F6A] drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]'>
            Finance and Accounting Apps Made by Hector Rojo
          </h1>
          <p className='text-lg md:text-xl text-center mt-4 text-gray-300'>
            Empowering your financial journey with cutting-edge tools
          </p>
        </motion.div>
        <div className='flex-grow flex items-center'>
          <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6'>
            {cards.map((card, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
              >
                <div className='bg-gray-800 bg-opacity-50 backdrop-blur-sm border-2 border-[#4295A5] transition-all duration-300 hover:shadow-[0_0_25px_rgba(66,149,165,0.5)] hover:-translate-y-1 cursor-pointer overflow-hidden rounded-lg p-4 flex flex-col h-full'>
                  <div className='flex items-center mb-2'>
                    {React.createElement(card.icon, {
                      className: 'w-6 h-6 mr-2 text-[#4295A5]',
                    })}
                    <h2 className='text-lg font-bold text-white'>
                      {card.title}
                    </h2>
                  </div>
                  <p className='text-gray-300 text-sm mb-4 flex-grow'>
                    {card.content}
                  </p>
                  <a
                    href={card.href}
                    className='mt-auto bg-[#4295A5] hover:bg-[#2A5F6A] text-white text-sm py-2 px-4 rounded text-center'
                  >
                    Go To App!
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
      <footer className='bg-gray-800 bg-opacity-50 backdrop-blur-sm py-4'>
        <div className='container mx-auto px-4 flex justify-center items-center text-sm'>
          <span className='text-gray-400 mr-2'>Crafted with passion by</span>
          <a
            href='https://tryheadquarters.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='transition duration-300 hover:shadow-lg hover:scale-105'>
              <HQLogo />
            </div>
          </a>
        </div>
      </footer>
    </div>
  );
}
