import './App.css';
import LandingPage from './components/LandingPage'; // Import LandingPage

function App() {
  return (
    <div className='App'>
      <LandingPage /> {/* Use LandingPage component */}
    </div>
  );
}

export default App;
